import React from 'react';
import TextField, {HelperText, Input} from '@material/react-text-field';
import '@material/react-material-icon/dist/material-icon.css';
import '@material/react-text-field/dist/text-field.css';
export default class SearchBox extends React.Component {
  state = {value: '', results:[]};

  onChange = ({currentTarget: {value: term}}) => {
    const {items} = this.props;
    const results = items.filter((item) => {
      return item.toLowerCase().includes(term.toLowerCase());
    });
    this.setState({results, value: term});
  }

  render() {
    return (
      <div>
        <TextField
          outlined
          dense
          label='Search'
          helperText={<HelperText persistent>Search by county or state</HelperText>}
        ><Input
           value={this.props.searchTerm}
           onChange={this.props.onChange} />
        </TextField>
        <ul>
          {
            this.state.results.map( (res, i) => (
              <li key={i}>{res}</li>
            ))
          }
        </ul>
      </div>
    );
  }
}