
import React, {Component} from 'react';
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';
import "@material/react-dialog/dist/dialog.css";


// eslint-disable-next-line import/no-webpack-loader-syntax
let {CountyMapComponent: CensusTractMap} = window.YPCCC;

const Modal = (props) => {
  if(!props.item){
    return;
  }
  const NewHaven = {name: "New Haven", _id: 212798};
  let {isOpen, onClose } = props;
  let {item} = props;

  return (
    // <div className="census-tract-map">
    //   <CensusTractMap county={item.id}/>
    // </div>
    // <React.Fragment>
      <Dialog open={isOpen} onClose={onClose}>
        {/* <DialogTitle><span></span>Heatwave Risk Perception: {item.name} County</DialogTitle> */}
        <DialogContent id={"map-root"} className="census-tract-map">
            <CensusTractMap county={item.id}/>
        </DialogContent>
     </Dialog>
    // </React.Fragment>
  );
}

export default Modal;