import React from 'react';
import Tab from '@material/react-tab';
import TabBar from '@material/react-tab-bar';

import '@material/react-tab-bar/dist/tab-bar.css';
import '@material/react-tab-scroller/dist/tab-scroller.css';
import '@material/react-tab/dist/tab.css';
import '@material/react-tab-indicator/dist/tab-indicator.css';

export default class Tabs extends React.Component {
  state = {activeIndex: 0};

  handleActiveIndexUpdate = (activeIndex) => this.setState({activeIndex});

  render() {
    return (
      <div>
        <TabBar
          activeIndex={this.props.activeTab}
          handleActiveIndexUpdate={activeIndex => this.props.onToggle(activeIndex)}
        >
          <Tab active>
            <span className='mdc-tab__text-label'>Absolute</span>
          </Tab>
          <Tab>
            <span className='mdc-tab__text-label'>Difference</span>
          </Tab>
        </TabBar>
      </div>
    );
  }
}