
import React, { Component } from 'react';
import './App.css';
import {counties} from "./counties.json";
import countiesById from "./countiesById.json";

import SearchBox from './SearchBox';
import Modal from './Modal';
import Tabs from './Tabs';

import Drawer, {DrawerAppContent} from '@material/react-drawer';
import Button from '@material/react-button';

let {CountyMapComponent: CensusTractMap} = window.YPCCC;

class CensusGrid extends Component {
  constructor(props){
    super(props);
    this.modal = React.createRef();
    let initialItem = countiesById[0];
    let selectedGridItem = {name:initialItem.CountyName, id: +initialItem._id};
    console.log(selectedGridItem)

    this.state = {
      items: counties,
      searchTerm: '',
      searchResults: counties,
      modalIsOpen: false,
      selectedGridItem: {},
      assetPaths: [
        '_tract.png',
        '_tract_diffs.png'
      ],
      assetPath: '_tract.png',
      activeTab: 0,
      mapComponent: []
    }
  }

  onSearchChange = ({currentTarget: {value: term}}) => {
    const {items} = this.state;
    const searchResults = items.filter((item) => {
      return item.toLowerCase().includes(term.toLowerCase());
    });
    this.setState({searchResults, searchTerm: term});
  }

  onGridItemClicked = (item, index) => {
    let county = countiesById.find(c => c.CountyState===item);
    console.log(this, item, county)

    let {
      CountyName, _id
    } = county;
    
    this.setState({
      modalIsOpen: true,
      selectedGridItem: {name: CountyName, id: +_id}
    })
    this.props.onSelection(+_id);
  }

  onModalClose = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  onToggleTabs = (activeIndex) => {
    let {assetPaths} = this.state;
    this.setState({
      assetPath: assetPaths[activeIndex],
      activeTab: activeIndex
    });
  }
  
  render(){
    const {
      items: tracts=[], assetPath, searchTerm, searchResults,
      modalIsOpen, selectedGridItem,
      activeTab, mapComponent
    } = this.state;
    console.log(selectedGridItem)
    return (
      <React.Fragment>
        <div>
          <div className="grid-controls">
            <Tabs activeTab={activeTab} onToggle={this.onToggleTabs}/>
            <SearchBox items={tracts} searchTerm={searchTerm} onChange={this.onSearchChange}/>
          </div>
          
          <div className="census-tract-grid">
            {searchResults.map( (tract, i) => (
              <div className="census-tract-grid-item" key={i} tract={tract} onClick={ e=> this.onGridItemClicked(tract, i)}>
                <img alt={'heatwave risk perception for ' + tract} src={'./images/' + tract + '/' + tract + assetPath}></img>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

class App extends Component {
  mainContentEl = React.createRef();
  map = React.createRef();
  drawerRef = React.createRef();
  state = {
    county: 6031,
    modalIsOpen: false
  }

  onCountySelection = (county) => {
    this.setState({county, modalIsOpen: true});
  }

  onDrawerOpen = () => {
    this.map.current.map.current.leafletElement.invalidateSize();
  }

  onDrawerClose = () => {
    this.setState({modalIsOpen: false});
    this.mainContentEl.current.querySelector('input, button').focus();
  }

  render() {
    let {county} = this.state;

    

    return (
      // <div className="App map-fixed">
      //   <header className="App-header">
      //     <h1>Heatwave Risk Perception</h1>
      //     <h2>Top 100 U.S. Counties by Population</h2>
      //   </header>
      //   <CensusGrid onSelection={this.onCountySelection}/>
      //   <CensusTractMap county={county}/>
      // </div>

      <div className="App">
        <Drawer
          modal
          open={this.state.modalIsOpen}
          onClose={this.onDrawerClose}
          onOpen={this.onDrawerOpen}
          innerRef={this.drawerRef}
        >
          <CensusTractMap ref={this.map} county={county}/>
        </Drawer>
        <DrawerAppContent >
          <div ref={this.mainContentEl}>
            <CensusGrid onSelection={this.onCountySelection} />
          </div>
        </DrawerAppContent>
      </div>
    );
  }
}

export default App;
